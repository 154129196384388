<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template :key="componentKey">
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage },
    ]"
  >
    <!-- <vx-tour :steps="steps" v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')" /> -->

    <!-- <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
    @updateRouterTransition = "updateRouterTransition" />-->

    <v-nav-menu
      :navMenuItems="editedNavMenuItems"
      title="Vuexy"
      parent=".layout--main"
      dashboardRoute="/org-owner/notice-board"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="editedNavMenuItems"
        />
        <!-- <the-clinic-navbar-vertical /> -->
      </template>

      <template v-else>
        <!-- <the-clinic-navbar-vertical /> -->
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :sidebar="iframe.sidebar"
          :showUserDropdown="iframe.userDropdown"
          :showCloseCta="iframe.closeCta"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div :class="[!isPosPage ? 'router-view p-6' : '']">
          <div class="router-content ">
            <setup-payment-banner v-if="showPaymentBanner"></setup-payment-banner>
            <transition :name="routerTransition">
              <div
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
                :class="['flex flex-wrap items-center router-header hidden md:block', !isPosPage ? 'mb-6' : '']"
              >
                <!-- <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>-->

                <!-- BREADCRUMB -->
                <vx-breadcrumb
                  class="ml-4"
                  v-if="$route.meta.breadcrumb"
                  :route="$route"
                  :isRTL="$vs.rtl"
                />

                <!-- DROPDOWN -->
                <!-- <vs-dropdown vs-trigger-click class="hidden ml-auto cursor-pointer md:block">
                  <vs-button radius icon="icon-settings" icon-pack="feather" />

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>

                </vs-dropdown>-->
              </div>
            </transition>

            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @changeRouteTitle="changeRouteTitle"
                  @setAppClasses="
                    (classesStr) => $emit('setAppClasses', classesStr)
                  "
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/vertical-nav-menu/org-owner/orgOwnerNavMenuItems.js";
import TheCustomizer from "@/layouts/components/customizer/TheCustomizer.vue";
import TheClinicNavbarVertical from "@/layouts/components/navbar/clinic/TheclinicNavbarVertical.vue";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/org-owner/TheOrgOwnerNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import LDClient from "launchdarkly-js-client-sdk";
import _ from "lodash";
import { mapActions, mapMutations } from "vuex";
import OrganizationHelper from "@/mixins/Organization";
import SetupPaymentBanner from '../../components/setup-payment-banner/setupPaymentBanner.vue';


const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    BackToTop,
    HNavMenu,
    // TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheClinicNavbarVertical,
    TheNavbarVertical,
    VNavMenu,
    SetupPaymentBanner,
    // VxTour
  },
  mixins: [OrganizationHelper],
  provide() {
    return {
      getRoleRouteName: this.getRoleRouteName,
    };
  },
  data() {
    return {
      editedNavMenuItems: [],
      componentKey: 0,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      navMenuItems: navMenuItems,
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
      steps: [
        {
          target: "#btnVNavMenuMinToggler",
          content: "Toggle Collapse Sidebar.",
        },
        {
          target: ".vx-navbar__starred-pages",
          content:
            "Create your own bookmarks. You can also re-arrange them using drag & drop.",
        },
        {
          target: ".i18n-locale",
          content: "You can change language from here.",
        },
        {
          target: ".navbar-fuzzy-search",
          content: "Try fuzzy search to visit pages in flash.",
        },
        {
          target: ".customizer-btn",
          content: "Customize template based on your preference",
          params: {
            placement: "left",
          },
        },
        {
          target: ".vs-button.buy-now",
          content: "Buy this awesomeness at affordable price!",
          params: {
            placement: "top",
          },
        },
      ],
      organizationId: null,
      organization: {},
      orgOwnerPermission: null,
      userId: null,
      iframe: {
        sidebar: true,
        navbar: true,
        userDropdown: true,
        footer: true,
      }
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    isPosPage() {
      return this.$route.name === "OrgOwnerPOS"
    },
    showPaymentBanner() {
        return process.env.VUE_APP_REGION == 'US'
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default")
          return "content-area-reduced";
        else if (this.verticalNavMenuWidth == "reduced")
          return "content-area-lg";
        else return "content-area-full";
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      else return "content-area-full";
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  async beforeMount() {
    const { badge: key, email, userRole } = this.$store.state.AppActiveUser;
    var user = {
      key: key,
      email: email,
      userRole,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);
    this.userId = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`))._id;
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.organization = _.find(
        JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        ).organizations,
        ["_id", this.organizationId]
      );
    }
    this.onLaunchDarklyReady();
  },
  methods: {
    ...mapActions("organization", ["fetchOrganizationClinicsList"]),
    ...mapActions("admin", ["fetchUserPermission", "checkPOSEnabledClinicsFromOrg"]),
    ...mapMutations(["SHOW_STORE"]),
    async onLaunchDarklyReady() {
      const user = this.$store.state.AppActiveUser;
      this.getOrganizationAllowedClinics();
      const organizationStatus = await this.getOrganizationComplianceStatus();
      const isComplianceEnabled = organizationStatus.isMonthlyComplianceEnabled;
      const isDrugbookEnabled = organizationStatus.isDrugbookEnabled;
      const isInventoryEnabled = organizationStatus.isInventoryEnabled;
      const isAnalyticsEnabled = organizationStatus.isAnalyticsEnabled;
      const hasDrugEntry = await this.checkClinicDrugEntry();
      let hide = [
        "Dashboard",
        "Events",
        "Resources",
        "Compliance",
        "Insights",
        "Consent Forms",
        "POS"
      ];
      const { data: permission } = await this.fetchUserPermission({
        userId: this.userId,
        organizationId: this.organizationId,
      })
      
      const {data: hasPOSEnabledClinics} = await this.checkPOSEnabledClinicsFromOrg({organizationId: this.organizationId});
      // const hideMenuNZ = ["POS","Events", "Financial Assistance", "Insights"];
      const hasConsentQuestionPermi = _.find(permission, (permi) => permi.permission.name === 'CustomConsentForm')
      const hasAftercarePermi = _.find(permission, (permi) => permi.permission.name === 'CustomAftercareDocument') && !this.$isUSRegion()
      this.editedNavMenuItems = _.pull(
        _.cloneDeep(this.navMenuItems).map((item) => {
          
          // if (item.name === "Insights"){
          //   if (_.find(permission, (permi) => permi.permission.name === 'Insights') && isAnalyticsEnabled) {
          //     return item;
          //   }
          //   return;
          // }
          /**
           * Scenario for showing POS in nav.
           * 1. If POS is enabled for an org-owner and
           * a clinic in the organization has POS enabled,
           * POS menu will show in their account.
           * 2. If POS is enabled for an org-owner but
           * there's no POS enabled clinics in the organization,
           * POS will be hidden in the nav.
           * 3. If POS is disabled for an org-owner and
           * a clinic in the organization has POS enabled,
           * POS wil be hidden in the nav.
           */
          if (item.name === "POS"){
            if (_.find(permission, (permi) => permi.permission.name === 'POS') && hasPOSEnabledClinics.data) {
              return item;
            }
            return;
          }
          
          if (item.name === "Insights"){
            if (_.find(permission, (permi) => permi.permission.name === 'Insights') == undefined && isAnalyticsEnabled) {
              return;
            }
          } 
          if (item.name === "nurse-dashboard"){
            if (_.find(permission, (permi) => permi.permission.name === 'Insights') == undefined && isAnalyticsEnabled) {
              return item;
            } else if (_.find(permission, (permi) => permi.permission.name === 'Insights') && isAnalyticsEnabled) {
              return;
            } else if (!isAnalyticsEnabled){
              return;
            }
          }
          /**
           * CustomConsentForm permission checker [US]
           * If enabled, show
           * then if disabled, hide
           */
          if (item.name === "Consent Forms") {
            if (hasConsentQuestionPermi) return item;
            return
          }

          if (process.env.VUE_APP_REGION === "US") {
            // Show dashboard & resources
            const dashboardIndex = hide.indexOf("Dashboard")
            const resourceIndex = hide.indexOf("Resources")
            hide.splice(dashboardIndex, 1)
            hide.splice(resourceIndex, 1)

            // Hide inventory, drugbook, clinics, staff & invoices
            hide.push("Inventory", "Drugbook", "org-ownerClinics", "Staff", "Invoice Management")
            if (
              (item.name === "Insights" && !isAnalyticsEnabled)
            ) {
              return;
            } else if (_.indexOf(hide, item.name) === -1) {
              return item;
            }
            return;
          } else if (process.env.VUE_APP_REGION === "AU") {
            if (item.header === "Compliance") {
              if (!isComplianceEnabled) {
                item.items = item.items.filter((item) => item.name !== 'Compliance');
              }
              if (
                !(isDrugbookEnabled && hasDrugEntry) &&
                !(isDrugbookEnabled && !hasDrugEntry) &&
                !(!isDrugbookEnabled && hasDrugEntry)
              ) {
                item.items = item.items.filter((item) => !['Drugbook', 'Inventory'].includes(item.name));
              }

              //Hide Compliance nav if sub menu is empty
              if(item.items.length === 0) {
                return;
              }
            }
            /**
             * Setting permi checker
             * - Consent form
             * - Aftercare docs [AU]
             * - Product list
             * - Custom Product list
             */
            if (item.header === "Settings") {
              if (!hasConsentQuestionPermi) {
                item.items = item.items.filter((item) => item.name !== 'Consent Forms');
              }
              if (!hasAftercarePermi) {
                item.items = item.items.filter((item) => item.name !== 'Aftercare Docs');
              }
              if (organizationStatus.isContourOrg) {
                item.items = item.items.filter((item) => item.name !== 'OrgOwner Products List');
              } else {
                item.items = item.items.filter((item) => item.name !== 'OrgOwner Custom Products List');
              }
            }
            if (item.name === "Insights" && !isAnalyticsEnabled) {
              return;
            }
          }
          return item;
        }),
        undefined
      );
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val == "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async getOrganizationComplianceStatus() {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      const { data } = await this.$store.dispatch(
        "organization/fetchOrganizationDetails",
        organizationId
      );
      return data.data;
    },
    async checkClinicDrugEntry() {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      const { data } = await this.$store.dispatch(
        "drugbook/checkClinicDrugEntries",
        organizationId
      );

      return data.data;
    },
  },
  created() {
    const tempIframe = JSON.parse(localStorage.getItem('iframe-view'))
    this.iframe.sidebar = tempIframe && tempIframe.sidebar === false ? false : true
    this.iframe.navbar = tempIframe && tempIframe.navbar === false ? false : true
    this.iframe.userDropdown = tempIframe && tempIframe.userDropdown === false ? false : true
    this.iframe.footer = tempIframe && tempIframe.footer === false ? false : true
    this.iframe.closeCta = tempIframe && tempIframe.closeCta === true ? true : false
    this.forceRerender();
    const color =
      this.navbarColor == "#fff" && this.isThemeDark
        ? "#10163a"
        : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  beforeDestroy() {

    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>
<style></style>
